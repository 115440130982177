import React from "react"
import Layout from "../components/layout"
import { graphql } from "gatsby"

import PrivacyPolicy from "../components/PrivacyPolicy/PrivacyPolicy"
import SEO from "../components/seo"

function PoltykaPrywatnosci({ data }) {
  return (
    <Layout>
      <SEO title="Poltyka Prywatności" />
      <PrivacyPolicy text={data.text} />
    </Layout>
  )
}

export default PoltykaPrywatnosci

export const query = graphql`
  query polityka {
    text: strapiPolitykaPrywatnosci {
      privacy_policy
    }
  }
`
