import React from "react"

import classes from "./PrivacyPolicy.module.scss"
import ReactMarkdown from "react-markdown"

const PrivacyPolicy = text => {
  return (
    <section id={classes.policy}>
      <h1 className={classes.header}>Polityka Prywatności</h1>
      <div className={classes.content}>
        <ReactMarkdown>{text.text.privacy_policy}</ReactMarkdown>
      </div>
    </section>
  )
}

export default PrivacyPolicy
